.Body {
  display: flex;
  width: 100%;
  align-items: center;
}
.bodyContainer {
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  align-items: center;
}
.boxHead {
  width: 100%;
  min-width: 40px;
  background-color: #fff;
  justify-content: space-between;
  padding: 15px;
  box-shadow: 0 0 0 0.25rem rgba(153, 38, 240, 0.25);
  align-items: center;
}

.faqBox {
  height: 157.5px;
  margin-bottom: 20px;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.box {
  width: 70%;
  margin-bottom: 30px;
  position: relative;
}

.answer {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  justify-content: center;
  border: 2px solid var(--grey-2, #e0e0e0);
  border-top-width: 0px;
  width: 100%;
  padding: 10px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: all 0.3s ease-in-out, color 0s, border-color 0s;
}
.contactDetails {
  justify-content: flex-start;
  padding: 10px;
  padding-top: 20px;
  align-items: center;
  width: 50%;
  align-self: flex-start;
}
.headText {
  font-size: 40px;
  padding: 10px;
  font-weight: 500;
}
.subHeading {
  font-size: 24px;
  color: rgb(73, 69, 69);
}

.footer {
  width: 95%;
  height: 300px;
  margin-top: 10px;
  opacity: 0.9;
  padding: 20px 40px;
  justify-content: center;
  align-items: center;
}
.icon {
  font-size: 50px;
  padding: 5px;
  border: 3px solid var(--grey-2, #e0e0e0);
  border-radius: 8px;
  margin: 5px;
  padding-left: 8px;
  padding-right: 8px;
}

.iconText {
  padding-left: 20px;
  font-size: 20px;
}

#text {
  color: black;
  font-size: 20px;
}
.contact {
  width: "40%";
  height: 95%;
  padding: 10px 2px;
}

.line {
  width: 40%;
  height: 2px;
  background-color: grey;
  border-radius: 8px;
}
.modalTextWrapper {
  width: 50%;
  align-items: center;
  justify-content: center;
}
.Modal {
  height: 500px;
  width: 45%;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  border: 3px solid var(--grey-2, #e0e0e0);
}
.middle {
  border-radius: 20px;
  width: 95%;
  background-color: #f0f0f7;
  opacity: 0.9;
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding: 15px;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 800px) {
  .middle {
    flex-direction: column;
  }
  .Modal {
    width: 90%;
    align-self: center;
  }
  .modalTextWrapper {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}

#midSizeText {
  font-size: 40px;
  padding: 10px;
  font-weight: 500;
}

#normalSizeText {
  font-size: 20px;
  padding: 10px;
  font-weight: 500;
}
.submit {
  width: 100%;
  background-color: #18468f;
  border-radius: 10px;
  height: 15%;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  cursor: pointer;
}