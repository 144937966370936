.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.stack {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 25px;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 10px;
  justify-content: center;
  margin-top: 20px;
}

.pagination li {
  display: inline;
  margin-right: 15px;
}

.pagination a {
  text-decoration: none;
  cursor: pointer;
  font-size: 13px;
  padding: 6px 12px;
  color: #717070;
  font-weight: 600;
  font-family: Poppins;
  align-items: center;
}

.pagination a:hover {
  background: #f5f5f5;
}

.active a {
  padding: 3px 10px;
  cursor: pointer;
  background: white;
  color: #18468f;
  border-radius: 20px;
  border: 1px solid #18468f;
}

.sub-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.break-me {
  padding: 6px 12px;
}

.listCardContainer{
  width: 100%;
  min-height: 56px;
  background: #ffffff;
  border: 1px solid #E8ECF4;
  align-items: center;
  border-radius: 8px;
  padding: 10px 20px;
  position: relative;
}

.listWrapper {
  width: 60%;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding-left: 20px;
}

.listVersionNumber{
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  font-family: Poppins;
  color: #323232
}

.listVersionDateWrapper{
  width: 23%;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  right: 20px;
  align-self: flex-end;
}

.listPdfIcon{
  cursor: pointer;
  padding-bottom: 3px;
}

.listPdfWrapper{
  cursor: pointer;
  gap: 8px;
}

.listLightGreyText{
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  font-family: Poppins;
  color:#717070
}

.listBlackText{
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  font-family: Poppins;
  color:#323232
}