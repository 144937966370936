.selected-document {
  display: flex;
  width: 208px;
  padding: 6px 10px;
  align-items: center;
  gap: 21px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid var(--LIGHT-BLUE, #e8ecf4);
  background: #f3f5f9;
}

.hoverText {
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
}

.bodyText {
  color: var(--text-2, #717070);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

.hoverCard{
  border-radius: 8px;
}