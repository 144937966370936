.DragAndDrop{
   min-width: 24%;
   max-height: 15.2rem;
   min-height: 15.2rem;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
}

.PrecedentStack{
  min-width: 100%;
  height: 100%;
  /* background-color: red; */
}

/* Small screens */
@media only screen and (max-width: 650px) {
  .DocStack {
    flex-direction: column;  
    display: flex;  
    height: fit-content;
    width: 97%;
  }
  .DragAndDrop {
    height: 14rem;
    padding:1%
  }
  .PrecedentStack{
    /* width: 100%; */
    height: 100%;
    padding-bottom: 1%;
  }
}

