.ProjectContainer {
  width: 97%;
  border-radius: 8px;
  justify-content: center;
  margin-bottom: 2%;
  background-color: #ffffff;
  margin-top: 1%;
  margin-left: 0.3%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: space-between;
  align-items: flex-start;
}

.ProjectDetails {
  width: 80%;
  height: 100%;
  background-color: "";
  padding-left: 15px;
  align-items: flex-start;
  .tttt {

    border-radius: 8px !important;
  }
}

@media (max-width: 700px) {
  .ProjectContainer {
    flex-direction: column;
    align-items: flex-start;
    width: 94%;
  }
  .ProjectCard {
    width: 100%;
    align-items: flex-start;
    margin-left: 3%;
    margin-top: 0%;
  }
  .ProjectCardStack {
    width: 100%;
    padding: 1%;
  }
  .LightGreyBG {
    margin-top: 5%;
  }
  .ProjectDetails {
    width: 100%;
    margin-left: 0%;
    padding-bottom: 2%;
  }
  .ProjectDragAndDrop {
    height: 4rem;
  }
}
