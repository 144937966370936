.progressBarContainer {
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 20px 15px;
  gap: 20px;
}

.progressBarWrapper {
  width: 100%;
  height: 25px;
  border: 1px solid #18468f;
  border-radius: 13px;
  overflow: hidden;
  background-color: #ffffff;
  align-items: flex-start;
}

.progressBar {
  height: 100%;
  background-color: #18468f;
  border-radius: 13px;
  position: relative;
  overflow: hidden;
}
.progressBar::before,
.progressBar::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 20%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.2) 100%
  );
  animation: blink 2s linear infinite;
  border-radius: 13px;
}

.progressBar::after {
  left: 100%;
  animation-delay: 1s; /* Delay the start of the second blinking section */
}

@keyframes blink {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

.progressText {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #ffffff;
  font-size: 14px;
  font-family: Poppins;
  line-height: 16px;
}

.progressBarText {
  font-size: 16px;
  font-family: Poppins;
  line-height: 20px;
  color: #323232;
}
