.LightGreyCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 2%;
  /* background: var(--LIGHT-BLUE, #E8ECF4); */
}

.LightGreyBG {
  background-color: #f8f8f8;
  margin-bottom: 2%;
  height: 477px;
  width: 100%;
  position: absolute;
  z-index: -10;
  border-radius: 0.4rem;
  margin-top: 2.5%;
}

.ProjectDragAndDrop {
  justify-content: center;
  align-items: center;
  width: 97%;
  align-self: center;
  flex-direction: row;
}

.DragAndDropCard {
  justify-content: center;
  align-items: center;
  width: 95%;
  align-self: center;
}

.DragWrapper {
  width: "100%";
}

.filterItem {
  height: 36px;
  padding: 6px 13px;
  font-size: 12px;
  color: #323232;
  font-weight: 400;
  font-family: Poppins;
  line-height: 24px;
  cursor: pointer;
}
.filterItem:hover {
  background: var(--LIGHT-BLUE, #e8ecf4);
}

.redDot {
  width: 8px;
  height: 8px;
  background: var(--primary-red, #a82c0c);
  border-radius: 8px;
  position: absolute;
  top: 6px;
  right: 6px;
}

.helpContainer {
  position: fixed;
  bottom: 0;
  right: 3px;
  z-index: 6;
  align-items: flex-end;
}

.fieldWrapper {
  width: 100%;
}

.helpLabel {
  color: #1f2430;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
}

.helpDescription {
  color: var(--text-2, #717070);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  max-width: 291px;
}
.helpHeadContainer {
  width: 100%;
  gap: 12px;
}

.helpHeading {
  justify-content: space-between;
  width: 100%;
}
.helpHeadText {
  color: var(--text-1, #323232);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 100% */
}
.helpBox {
  display: inline-flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 12px;
  background: #fff;
  min-height: 490px;
  width: 368px;
  box-shadow: 0px 8px 60px 0px rgba(0, 0, 0, 0.4);
  margin-right: 10px;
}

.getHelpButton {
  cursor: pointer;
  display: flex;
  width: 100%;
  padding: 14px 33px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--WHITE, #fff);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
  text-align: center;
  border-radius: 5px;
  background: var(--primary-blue, #18468f);
  transition: background-color 0.3s ease;
}

@media (max-width: 700px) {
  .ProjectContainer {
    flex-direction: column;
    align-items: flex-start;
    width: 94%;
  }
  .ProjectCard {
    width: 100%;
    align-items: flex-start;
    margin-left: 3%;
    margin-top: 0%;
  }
  .ProjectCardStack {
    width: 100%;
    padding: 1%;
  }
  .LightGreyBG {
    margin-top: 5%;
  }
  .ProjectDetails {
    width: 100%;
    margin-left: 0%;
    padding-bottom: 2%;
  }
  .ProjectDragAndDrop {
    height: 4rem;
    flex-direction: column;
  }
}

@media (max-width: 850px) {
  .ProjectDragAndDrop {
    flex-direction: column;
    width: "100%";
  }
}
