.deleteContainer {
  width: 480px;
  flex-shrink: 0;
  justify-content: space-between;
}

.deleteProjectContainer {
  width: 480px;
  flex-shrink: 0;
  justify-content: space-between;
}

.deleteVersionContainer {
  width: 480px;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
}

.deleteProjectLoader {
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 30px 25px;
  align-self: center;
}

.horizontalLine {
  border-radius: 5px 5px 0px 0px;
  border: 1px solid var(--Light-Grey, #dfdfdf);
  background: var(--Light-Grey, #dfdfdf);
  width: 100%;
  height: 1px;
  flex-shrink: 0;
  align-self: flex-end;
}
.deleteContentText {
  color: var(--black, #323232);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.deleteBottom {
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.deleteContentText1 {
  color: var(--black, #323232);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.deleteCancelButton {
  display: flex;
  min-width: 120px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--primary-red, #a82c0c);
  color: var(--white-BG, #f4f4f4);
  text-align: right;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  cursor: pointer;
}

.deleteButton {
  display: flex;
  min-width: 120px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--Red-light, #f6eae7);
  color: var(--primary-red, #a82c0c);
  text-align: right;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  cursor: pointer;
}

.restoreButton {
  display: flex;
  min-width: 120px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--primary-green, #13540c);
  color: var(--white-BG, #f4f4f4);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
}



