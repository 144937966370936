.container {
  flex-shrink: 0;
  border-radius: 8px;
  background: #ecf0f9;
  align-items: center;
  padding: 8px;
}

.uploadModal {
  justify-content: space-evenly;
  width: 100%;
  min-height: 15rem;
  align-items: center;
  padding: 8px;
}

.loader {
  justify-content: center;
  align-items: center;
  background: #fff;
  width: 100%;
  height: 14rem;
  border-radius: 8px;
}

.title {
  width: 99%;
  /* height: 10%; */
  flex-shrink: 0;
  border-radius: 8px 8px 0px 0px;
  align-items: center;
  justify-content: space-between;
  /* padding-right: 18px; */
}

.titletext {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  color: #323232;
  line-height: 26px;
  font-family: Poppins;
}

.heading {
  width: 97.5%;
  height: 9%;
  border-radius: 8px;
  background: var(--white, #fff);
  border: 1px solid var(--grey-2, #e0e0e0);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
  margin-bottom: 4px;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}

.body {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d8e1eb;
  background: var(--white, #fff);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
  padding: 10px;
  gap: 16px;
}

.button {
  min-width: 62px;
  width: 62px;
  height: 66px;
  border-radius: 6px;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
}

.sendText {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  font-family: Poppins;
}
.textContainer {
  width: 100%;
  gap: 6px;
}
.textWrapper {
  width: 70px;
  height: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--LIGHT-BLUE, #e8ecf4);
  background: var(--WHITE, #fff);
}
.textWrapper1 {
  width: 70px;
  height: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--WHITE, #fff);
}
.text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: var(--black, #323232);
  text-align: left;
  font-family: Poppins;
}

.subject {
  width: 98%;
  flex-shrink: 0;
  border-bottom: 1px solid var(--light-grey, #dfdfdf);
  background: #fff;
  margin-top: 1.5%;
  align-self: center;
}

.subjectContainer {
  margin-top: 1%;
  margin-left: 15%;
  justify-content: flex-start;
  align-items: center;
}

.item {
  align-items: center;
  width: 15.5%;
  height: 80%;
  padding: 1% 8px;
  justify-content: space-between;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #ecf0f9;
  background: #fff;
  margin-left: 1.3%;
  position: relative;
  cursor: pointer;
  z-index: 2;
}

.itemStart {
  width: "80%";
  justify-content: flex-start;
  align-items: center;
}

.arrow {
  position: absolute;
  right: 15px;
  align-items: flex-end;
}

.itemText {
  text-align: center;
  font-size: 90%;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding-left: 4px;
}

.dropDown {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 89%;
}

.bodyTextWrapper {
  /* padding: 8px 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; */
}

.loading {
  margin: 2%;
}
.shareContentText {
  color: var(--black, #717070);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; /* 150% */
}
.SendButton {
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  background-color: white;
  width: 100%;
  margin-top: 2px;
  border-radius: 0 0 8px 8px;
}

.Send {
  display: flex;
  min-width: 120px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  color: var(--white-BG, #f4f4f4);
  text-align: right;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  cursor: pointer;
}

.goBack {
  display: flex;
  min-width: 120px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  text-align: right;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  cursor: pointer;
}
.shareTrackVersions {
  display: flex;
  width: 430px;
  padding: 10px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Red-light, #e8ecf4);
  background: #e8ecf4;
  align-self: center;
  z-index: 2;
}

.shareTrackVersionsCard {
  display: flex;
  width: 410px;
  padding: 8px 16px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--Red-light, #e8ecf4);
  background: var(--WHITE, #fff);
  gap: 10px;
  position: relative;
}
.shareTrackContainer {
  width: 480px;
  flex-shrink: 0;
  justify-content: space-between;
}
.shareTrackSmallText {
  color: var(--WHITE, #fff);
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 200% */
}

.shareTrackRedText {
  color: var(--primary-Red, #a82c0c);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  border-radius: 4px;
  border: 1px solid var(--Red-light, #fcf0ed);
  background: var(--WHITE, #fff);
  display: flex;
  padding: 4px 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px; /* 116.667% */
}

.shareTrackMedText {
  color: var(--Gray-900, #101828);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 240px;
}

.suggestionListContainer {
  z-index: 2;
  max-width: 400px;
  min-width: 200px;
  width: 280px;
  top: 34px;
  left: -3px;
  border-radius: 8px;
  border: 1px;
  border: 1px solid #f2f4f7;
  box-shadow: 0px 4px 6px -2px #10182808;
  box-shadow: 0px 12px 16px -4px #10182814;
  overflow: hidden;
  position: absolute;
  background: white;
}

.suggestionList {
  padding: 4px 12px;
  border-bottom: 1px solid #e8ecf4;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.suggestionUserName {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #101828;
}

.suggestionUserEmail {
  font-family: Poppins;
  font-size: 11px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #667085;
}

.tick {
  display: none;
}

.tick:hover {
  display: flex;
}

.suggestionList:hover {
  background-color: #e8ecf4;
}

.suggestionList:hover .tick {
  background-color: #e8ecf4;
  display: block;
}
