.login{
  --login-signed-out-button-background:#F4F4F4;
--login-signed-out-button-hover-background: #DFDFDF;
--login-signed-out-button-text-color: black;
/* --login-signed-in-background: #DFDFDF ; */
--login-signed-in-hover-background: #F4F4F4;
--login-button-padding: 2px;
/* --card-height:240px;
--card-width:max-content !important; */
--card-align-items:center;
--card-justify-content:center;
--login-popup-text-color: brown;
--login-popup-command-button-background-color: orange;
--login-popup-padding: 10px ;
--login-add-account-button-text-color: #101828;
--login-add-account-button-background-color:#f4f4f4;
--login-add-account-button-hover-background-color: #DFDFDF;
--login-command-button-background-color: #f4f4f4;
--login-command-button-hover-background-color: #DFDFDF;
--login-command-button-text-color: black;
--body-font: 'Poppins'
/** person component tokens **/
--person-line1-text-color: #323232;
--person-line2-text-color: #101828;
--person-background-color: white;
/* background-color: #18468F; */
.login .mgt-flyout-content {
  width: var(--card-width) !important;
  max-width: var(--card-max-width) !important;
}
}