/* Small screens */
@media only screen and (max-width: 600px) {
    .searchBox {
      width: 100%;
      max-width: 200px;
    }
  }
  
  /* Medium screens */
  @media only screen and (min-width: 601px) and (max-width: 1200px) {
    .searchBox {
      width: 60%;
      max-width: 300px;
    }
  }
  
  /* Large screens */
  @media only screen and (min-width: 1201px) {
    .searchBox {
      width: 30%;
      max-width: 400px;
    }
  }
  
  /* General styles */
  .searchBox {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: inherit;
    height: 35px;
    font-size: 16px;
  }
  
  .searchBox .iconContainer {
    background-color: #F0F0F0;
    align-items: flex-start;
  }

  .input-wrapper{
    background-color: white;
    border-radius: 4px;
    display: flex;
    align-items: center;
  }
  input{
    background-color: transparent;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    font-family: 'Poppins';
    border: none;
    margin-left: 10px;
  }

  input:focus{
    outline: none;
  }