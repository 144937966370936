.projectName {
  margin-bottom: 4px;
  justify-content: space-between;
  align-items: center;
}

.version {
  display: flex;
  padding: 2px 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 1px solid var(--LIGHT-BLUE, #e8ecf4);
  background: var(--WHITE, #fff);
  color: var(--text-1, #323232);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.newVersion {
  display: flex;
  padding: 4px 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 1px solid var(--info, #007cff);
  background: var(--info, #007cff);
  color: var(--WHITE, #fff);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 116.667% */
}

.dot{
    position: absolute;
    bottom: -23px;
    left:10px;
    z-index: -3;

}