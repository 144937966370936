.pdf-tooltip {
    background-color: #FCF0ED !important;
    color: #A82C0C !important;
    padding: 4px !important;
    font-size: 11px !important;
    line-height: 16px !important;
    font-weight: 400 !important;
    font-family: Poppins !important;
    border-radius: 6px !important;
    border: 1px solid #A82C0C  !important; 
    
}
.pdf-tooltip-arrow {
    border-right: 1px solid #A82C0C  !important; 
    border-bottom: 1px solid #A82C0C  !important;
    border-radius: 1px;
    right: -5px;
    
}
.custom-tooltip {
    background-color: #E8ECF4 !important;
    color: #18468F !important;
    padding: 4px !important;
    font-size: 11px !important;
    line-height: 16px !important;
    font-weight: 400 !important;
    font-family: Poppins !important;
    border-radius: 4px !important;
    border: 1px solid #18468F  !important; 
    
  }
  .custom-tooltip-arrow {
    border-right: 1px solid #18468F  !important; 
    border-bottom: 1px solid #18468F  !important;
    border-radius: 1px;
    right: -5px;
  }